import { Badge, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

function SettingsIconCB({ setAnchorEl, managerMode }) {
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  console.log("PRINTING SETTINGS");
  if(managerMode===-1){
  return (
    <Badge badgeContent="New" color="success" style={{ right: 10, top: 8 }}>
      <IconButton
        size="large"
        aria-label="user settings"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="secondary"
        style={{ right: -10, top: -8}}
      >
        <SettingsIcon />
      </IconButton>
    </Badge>
  );
  }
  return (<IconButton
    size="large"
    aria-label="user settings"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    onClick={handleMenu}
    color="secondary"
  >
    <SettingsIcon />
  </IconButton>)
}
export default SettingsIconCB;

import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  Snackbar,
  Alert,
  LinearProgress,
  Paper,
} from "@mui/material";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import Layout from "../components/layout/Layout";
import { useState, useEffect, useCallback } from "react";
import DeletionModal from "../components/ui/DeletionModal";
import useRefresh from "../components/Refresh/refresh";
import GameRegistrationModal from "../components/Signups/GameRegistrationModal";
import IndividualGame from "../components/WeekAtAGlance/IndividualGame";
import useNoSubscriptionRedirect from "../components/Refresh/noSubscriptionRedirect.tsx";

function EventPage({
  setEventId,
  clubId,
  setGameDate,
  devPort,
  waiverNotifications,
  managerMode,
  setGamePoloType,
  setPricingTab,
  setLeasePrice,
  betaMode
}) {
  // const classes = useStyles();

  let navigate = useNavigate();
  /*if(clubId===-1){
        navigate('/')
    }*/
  useRefresh(clubId, devPort);
  useNoSubscriptionRedirect(betaMode, clubId, managerMode, devPort);
  const [registerModal, setRegisterModal] = useState(false);
  const [comingGamesString, setComingGamesString] = useState();
  const [gameList, setGameList] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD")); //'2020-01-01')
  const [endDate, setEndDate] = useState("2999-01-01");
  const [gameDelete, setGameDelete] = useState();
  const [gameToDelete, setGameToDelete] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //Control the Modal variables
  const [registerDate, setRegisterDate] = useState();
  const [registerStartTime, setRegisterStartTime] = useState();
  const [registerField, setRegisterField] = useState();
  const [registerManagerNotes, setRegisterManagerNotes] = useState();
  const [registerGameType, setRegisterGameType] = useState("OUTDOOR");
  const [selectedPrice, setSelectedPrice] = useState();
  const [recipient, setRecipient] = useState();
  const [recipientId, setRecipientId] = useState();
  const [recipientStripeId, setRecipientStripeId] = useState();
  const [numberHorseChukker, setNumberHorseChukker] = useState();
  const [potentialDeleteGameId, setPotentialDeleteGameId] = useState();

  useEffect(() => {
    if (recipientStripeId) {
      fetch(
        devPort +
          "system_get_user_id_from_connect_id/" +
          clubId +
          "/" +
          recipientStripeId
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setRecipientId(data);
        });
    } else {
      console.log("RIGHT THERE");
      console.log(recipientStripeId);
      console.log(recipient);
      setRecipientId(null);
    }
  }, [clubId, recipientStripeId, devPort, recipient]);

  const filterGames = useCallback(() => {
    fetch(devPort + "game_list/" + clubId + "/" + startDate + "/" + endDate)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("LIST OF GAMES");
        console.log(data);
        const newGames = data.map((game) => {
          //Moment is setting the day to one day before when time is 00?
          //return [game[0], game[1], moment(game[3]).add(1, "days").format('YYYY-MM-DD')]
          // TODO: Jeremie to refactor this sketchy thing
          return {
            game_id: game[0],
            field_location: game[1],
            game_date: moment(game[4]), //.format('MMMM Do YYYY'),
            game_type: game[6],
            lease_price: game[7],
            manager_notes: game[8],
            lease_recipient: game[9],
            number_horse_chukker: game[10],
            //New fields
            lessonPrice: game[7],
            id: game[0],
            time: moment(game[4]).format("HH:mm"),
            date: moment(game[4]).format("dddd MMMM Do YYYY, HH:mm"),
          };
        });
        if (newGames.length === 0) {
          setComingGamesString("No games available for the selected range");
        } else {
          setComingGamesString("Upcoming Games");
        }
        //console.log(newGames.sort((a, b) => a.game_date - b.game_date));
        setGameList(newGames.sort((a, b) => a.game_date - b.game_date));
        setIsLoading(false);
      });
  }, [clubId, devPort, endDate, startDate]);

  useEffect(() => {
    filterGames();
  }, [filterGames]);

  function deleteGame(game_id) {
    fetch(devPort + "delete_game/" + game_id).then((data) => {
      setDeleteModal(false);
      setIsLoading(true);
      filterGames();
      setMessageContent("deleted from");
      setAlertMessage(true);
    });
  }
  const [alterMessage, setAlertMessage] = useState(false);
  const [messageContent, setMessageContent] = useState("added to");

  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications} betaMode={betaMode}>
      <Snackbar
        open={alterMessage}
        autoHideDuration={3000}
        onClose={() => {
          setAlertMessage(false);
        }}
      >
        <Alert severity="success">
          {" "}
          Game has been {messageContent} the schedule!
        </Alert>
      </Snackbar>

      <Card variant="outlined">
        <CardContent>
          <Stack spacing={3}>
            <Grid2 container spacing={2}>
              <Grid2 xs={4}>
                <DatePicker
                  label="From"
                  onChange={(event) => {
                    setStartDate(moment(event["$d"]).format("YYYY-MM-DD"));
                    console.log(moment(event["$d"]).format("YYYY-MM-DD"));
                    console.log(event["$d"]);
                  }}
                />
              </Grid2>
              <Grid2 xs={4}>
                <DatePicker
                  label="To"
                  onChange={(event) => {
                    setEndDate(moment(event["$d"]).format("YYYY-MM-DD"));
                    console.log(moment(event).format("YYYY-MM-DD"));
                  }}
                />
              </Grid2>
              <Grid2 xs={4}>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={filterGames}
                  style={{ height: "100%" }}
                >
                  Filter
                </Button>
              </Grid2>
            </Grid2>
            <DeletionModal
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              deleteString="Game"
              deleteId={gameDelete}
              setDeleteId={gameToDelete}
              onDelete={() => deleteGame(gameToDelete)}
            />
            {managerMode === -1 && (
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  setRegisterModal(true);
                }}
              >
                Create Game
              </Button>
            )}
            {managerMode === -1 && (
              <Button
                onClick={() => {
                  setPricingTab("Lease");
                  navigate("/lessonmanagepricing");
                }}
                variant="outlined"
                size="large"
                color="primary"
              >
                Set Membership/Lease Pricing
              </Button>
            )}
            {!isLoading && (
              <Stack spacing={3}>
                <h3>{comingGamesString}</h3>
                {gameList.map((event) => {
                  return (
                    <Paper elevation={12}>
                      <Card variant="elevation" key={event["game_id"]}>
                        <CardContent>
                          <Stack>
                            <Grid2 container xs={12}>
                              <Typography>
                                {event["game_date"].format("dddd") +
                                  " " +
                                  event["game_date"].format("YYYY-MM-DD")}
                              </Typography>
                            </Grid2>
                            <IndividualGame
                              key={event["game_id"]}
                              game={event}
                              managerMode={managerMode}
                              setEventId={setEventId}
                              clubId={clubId}
                              //setClubId={setClubId}
                              setLeasePrice={setLeasePrice}
                              setGameDate={setGameDate}
                              setRegisterDate={setRegisterDate}
                              setRegisterStartTime={setRegisterStartTime}
                              setRegisterField={setRegisterField}
                              setRegisterManagerNotes={setRegisterManagerNotes}
                              setRegisterGameType={setRegisterGameType}
                              setSelectedPrice={setSelectedPrice}
                              setRecipientStripeId={setRecipientStripeId}
                              setNumberHorseChukker={setNumberHorseChukker}
                              setPotentialDeleteGameId={
                                setPotentialDeleteGameId
                              }
                              setUpdateModal={setUpdateModal}
                            />
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  );
                })}
                <GameRegistrationModal
                  devPort={devPort}
                  accessMode="Create"
                  registerModal={registerModal}
                  setRegisterModal={setRegisterModal}
                  clubId={clubId}
                  setIsLoading={setIsLoading}
                  setAlertMessage={setAlertMessage}
                  setMessageContent={setMessageContent}
                  filterGames={filterGames}
                  registerDate={registerDate}
                  setRegisterDate={setRegisterDate}
                  registerStartTime={registerStartTime}
                  setRegisterStartTime={setRegisterStartTime}
                  registerField={registerField}
                  setRegisterField={setRegisterField}
                  registerManagerNotes={registerManagerNotes}
                  setRegisterManagerNotes={setRegisterManagerNotes}
                  registerGameType={registerGameType}
                  setRegisterGameType={setRegisterGameType}
                  selectedPrice={selectedPrice}
                  setSelectedPrice={setSelectedPrice}
                  recipient={recipient}
                  setRecipient={setRecipient}
                  numberHorseChukker={numberHorseChukker}
                  setNumberHorseChukker={setNumberHorseChukker}
                  modalType="save"
                />
                <GameRegistrationModal
                  devPort={devPort}
                  accessMode="Update"
                  registerModal={updateModal}
                  setRegisterModal={setUpdateModal}
                  clubId={clubId}
                  setIsLoading={setIsLoading}
                  setAlertMessage={setAlertMessage}
                  setMessageContent={setMessageContent}
                  filterGames={filterGames}
                  registerDate={registerDate}
                  setRegisterDate={setRegisterDate}
                  registerStartTime={registerStartTime}
                  setRegisterStartTime={setRegisterStartTime}
                  registerField={registerField}
                  setRegisterField={setRegisterField}
                  registerManagerNotes={registerManagerNotes}
                  setRegisterManagerNotes={setRegisterManagerNotes}
                  registerGameType={registerGameType}
                  setRegisterGameType={setRegisterGameType}
                  selectedPrice={selectedPrice}
                  setSelectedPrice={setSelectedPrice}
                  recipient={recipient}
                  setRecipient={setRecipient}
                  numberHorseChukker={numberHorseChukker}
                  setNumberHorseChukker={setNumberHorseChukker}
                  modalType="update"
                  gameId={potentialDeleteGameId}
                  setGameDelete={setGameDelete}
                  setGameToDelete={setGameToDelete}
                  setDeleteModal={setDeleteModal}
                  recipientId={recipientId}
                />
              </Stack>
            )}
            {isLoading && <LinearProgress color="secondary" />}
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default EventPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Toolbar,
  Box,
  AppBar,
  Typography,
  Button,
  Container,
  MenuItem,
  Badge,
} from "@mui/material";
import Asset1 from "./Asset1.png";
import SettingsMenu from "./SettingsMenu";
import SettingsIconCB from "./SettingsIcon";
//import Asset3 from './Asset3.png'

function GameTaskBar({
  pagesNav,
  displayContent,
  devPort,
  waiverNotifications,
  managerMode,
  betaMode
}) {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  function goToPage(page) {
    navigate(pagesNav[page]);
  }
  console.log("CHECKING MODE");
  console.log(displayContent);
  //<Switch checked={darkMode} onChange={() => { setDarkMode(!darkMode) }} />
  //<a href="https://www.chukkerbook.com">
  //<img src={Asset3} width={75} height={75} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} onClick={() => navigate('/')} />
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={Asset1}
            alt="Chukkerbook red logo"
            width={70}
            height={70}
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            onClick={() => navigate("/")}
          />
          {displayContent > -2 && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              {Object.keys(pagesNav).map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => goToPage(page)}
                  data-testid={"desktop-menu-" + page}
                >
                  <Typography textAlign="center">{page}</Typography>
                  <Badge
                    badgeContent={waiverNotifications}
                    color="secondary"
                    invisible={page !== "Players"}
                    style={{ bottom: 10 }}
                  />
                </MenuItem>
              ))}
            </Box>
          )}
          {displayContent > -2 && (
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {Object.keys(pagesNav).map((page) => (
                <Button
                  key={page}
                  onClick={() => goToPage(page)}
                  data-testid={"mobile-menu-" + page}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                  <Badge
                    badgeContent={waiverNotifications}
                    color="secondary"
                    invisible={page !== "Players"}
                    style={{ bottom: 10 }}
                  />
                </Button>
              ))}
            </Box>
          )}
          {(displayContent === -3 ||
            displayContent === -4 ||
            displayContent === -5) && (
            <Box
              style={{ marginLeft: "auto" }}
              sx={{
                //flexGrow: 1,
                display: { xs: "flex", md: "none", position: "right" },
              }}
            >
              {Object.keys(pagesNav).map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => goToPage(page)}
                  style={{ marginLeft: "auto" }}
                  data-testid={"mobile-menu-" + page}
                >
                  <Typography textAlign="center" color="secondary">
                    {page}
                  </Typography>
                </MenuItem>
              ))}
              {/*{(displayContent <= -4 || displayContent >= -1) && (
                <SettingsIconCB setAnchorEl={setAnchorEl} />
              )}
              {(displayContent <= -4 || displayContent >= -1) && (
                <SettingsMenu
                  devPort={devPort}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              )}*/}
            </Box>
          )}
          {(displayContent === -3 ||
            displayContent === -4 ||
            displayContent === -5) && (
            <Box
              style={{ marginLeft: "auto" }}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              {Object.keys(pagesNav).map((page) => (
                <Button
                  key={page}
                  onClick={() => goToPage(page)}
                  sx={{ my: 2, color: "white", display: "block" }}
                  data-testid={"desktop-menu-" + page}
                >
                  {page}
                </Button>
              ))}
            </Box>
          )}
          {displayContent === -2 && (
            <Box
              style={{ marginLeft: "auto" }}
              sx={{
                display: { xs: "flex", md: "none", position: "right" },
              }}
            />
          )}
          {displayContent === -2 && (
            <Box
              style={{ marginLeft: "auto" }}
              sx={{ display: { xs: "none", md: "flex" } }}
            />
          )}
          {(displayContent <= -4 || displayContent >= -2) && (
            <SettingsIconCB setAnchorEl={setAnchorEl} managerMode={managerMode} />
          )}
          {(displayContent <= -4 || displayContent >= -2) && (
            <SettingsMenu
              devPort={devPort}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              managerMode={managerMode}
              betaMode={betaMode}
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default GameTaskBar;

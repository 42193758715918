import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ProRequest from "./ProRequest";
import PaidLease from "./PaidLease";
import Edit from "./EditButton";
import Delete from "./DeleteButton";
import ChukkerbookAccount from "./ChukkerbbokAccount";
import SignedWaiver from "./SignedWaiver";
import ProPlayer from "./ProPlayer";
//import EditCredit from "./EditCreditButton";
import Archive from "./ArchiveButton.tsx";
import { useCallback, useMemo, useState } from "react";
import { visuallyHidden } from "@mui/utils";

function PlayerName({ playerName, playerId, isPro, proColorMapping }) {
  return (
    <Typography
      color={
        isPro
          ? proColorMapping && proColorMapping[playerId]
            ? proColorMapping[playerId][0]
            : "orange"
          : "regular"
      }
    >
      {playerName}
    </Typography>
  );
}

function get_columns(page, managerMode) {
  if (page === "games") {
    if (managerMode === -1) {
      return [
        "Player Name",
        "Chukkers",
        "Horse Lease Chukkers",
        "Pro Request",
        "Note",
        "Paid Lease",
        "Signed Waiver",
        "Edit",
        "Delete",
      ];
    } else {
      return ["Player Name", "Chukkers", "Pro Request"];
    }
  }
  if (page === "lessons") {
    if (managerMode === -1) {
      return [
        "Player Name",
        "Coach",
        "Note",
        "Paid Lesson",
        "Signed Waiver",
        "Delete",
      ];
    } else {
      return ["Player Name", "Coach"];
    }
  }
  if (page === "members") {
    if (managerMode === -1) {
      return [
        "Player Name",
        "ChukkerBook Account",
        "Pro Player",
        "USPA Rating",
        "Club Rating",
        "Signed Waiver",
        //"Available Credits",
        "Latest Signup Date",
        "Edit",
        "Archive",
        "Delete",
      ];
    } else {
      return ["Player Name", "ChukkerBook Account", "Pro Player"];
    }
  }
  return [];
}

const columnMapper = {
  "Player Name": "first_name",
  "ChukkerBook Account": "has_chukkerbook_account",
  "Pro Player": "is_club_pro",
  "USPA Rating": "uspa_rating",
  "Club Rating": "club_rating",
  "Latest Signup Date": "last_signup",
  Archive: "is_archived",
  "Signed Waiver": "up_to_date_waiver",
  "Horse Lease Chukkers": "count_chukkers_leased",
  "Chukkers":  "count_chukkers_requested",
  "Pro Request": "selected_pro_id",
  "Note": "signup_notes",
  "Paid Lease": "paid_lease",
  "Paid Lesson": "paid_lesson",
  "Coach": "coach"
}

function PlayerSignupTable({
  signups,
  searchField,
  proColorMapping,
  page,
  managerMode,
  setPlayerId,
  setPlayerFirstName,
  setPlayerLastName,
  setDeleteModal,
  setplayerModalEdit,
  setPlayerRating,
  setPlayerClubRating,
  setPlayerUSPARating,
  setPlayerIsPro,
  setChukkerNumber,
  setProRequest,
  setPlayerHasSignedWaiver,
  setPlayerModal,
  proMapping,
  setCreditsModal,
  updateArchiveStatus,
  doNotShowArchive,
}) {
  let teamColor = "#5D8AA8";
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const columns = get_columns(page, managerMode);
  console.log(signups);
  function get_rows(page, row) {
    let player_mapping = {};
    if (page === "games") {
      player_mapping = {
        "Player Name": (
          <PlayerName
            playerName={row["first_name"] + " " + row["last_name"]}
            playerId={row["player_id"]}
            isPro={row["is_club_pro"]}
            proColorMapping={proColorMapping}
          />
        ),
        Chukkers: row["count_chukkers_requested"],
        "Horse Lease Chukkers": row["count_chukkers_leased"]
          ? row["count_chukkers_leased"]
          : "-",
        "Pro Request": (
          <ProRequest
            requestedPro={row["selected_pro_id"]}
            proColorMapping={proColorMapping}
            proMapping={proMapping}
          />
        ),
        Note: row["signup_notes"] ? row["signup_notes"] : "-",
        "Paid Lease": <PaidLease hasPaid={row["paid_lease"]} />,
        "Signed Waiver": <SignedWaiver hasSigned={row["up_to_date_waiver"]} />,
        Edit: (
          <Edit
            playerInfo={row}
            setplayerModalEdit={setplayerModalEdit}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setPlayerRating={setPlayerRating}
            setPlayerClubRating={setPlayerClubRating}
            setPlayerUSPARating={setPlayerUSPARating}
            setPlayerIsPro={setPlayerIsPro}
            setChukkerNumber={setChukkerNumber}
            setProRequest={setProRequest}
            setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
            setPlayerModal={setPlayerModal}
          />
        ),
        Delete: (
          <Delete
            playerInfo={row}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setDeleteModal={setDeleteModal}
          />
        ),
      };
    }
    if (page === "lessons") {
      player_mapping = {
        "Player Name": (
          <PlayerName playerName={row["first_name"] + " " + row["last_name"]} />
        ),
        Coach: (
          <ProRequest
            requestedPro={row["coach"]}
            proColorMapping={proColorMapping}
            proMapping={proMapping}
          />
        ),
        Note: row["signup_notes"] ? row["signup_notes"] : "-",
        "Paid Lesson": <PaidLease hasPaid={row["paid_lesson"]} />,
        "Signed Waiver": <SignedWaiver hasSigned={row["up_to_date_waiver"]} />,
        Edit: (
          <Edit
            playerInfo={row}
            setplayerModalEdit={setplayerModalEdit}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setPlayerRating={setPlayerRating}
            setPlayerClubRating={setPlayerClubRating}
            setPlayerUSPARating={setPlayerUSPARating}
            setPlayerIsPro={setPlayerIsPro}
            setChukkerNumber={setChukkerNumber}
            setProRequest={setProRequest}
            setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
            setPlayerModal={setPlayerModal}
          />
        ),
        Delete: (
          <Delete
            playerInfo={row}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setDeleteModal={setDeleteModal}
          />
        ),
      };
    }
    if (page === "members") {
      player_mapping = {
        "Player Name": (
          <PlayerName playerName={row["first_name"] + " " + row["last_name"]} />
        ),
        "ChukkerBook Account": (
          <ChukkerbookAccount
            hasAccount={row["has_chukkerbook_account"]}
            disabled={row["is_archived"]}
          />
        ),
        "Pro Player": (
          <ProPlayer isPro={row["is_club_pro"]} disabled={row["is_archived"]} />
        ),
        "USPA Rating": row["uspa_rating"],
        "Club Rating": row["club_rating"],
        "Signed Waiver": (
          <SignedWaiver
            hasSigned={row["up_to_date_waiver"]}
            disabled={row["is_archived"]}
          />
        ),
        "Latest Signup Date": (
          <Typography>
            {row["last_signup"] ? row["last_signup"].format("ll") : "-"}
          </Typography>
        ),
        /*"Available Credits": (
          <EditCredit
            setPlayerId={setPlayerId}
            playerInfo={row}
            setCreditsModal={setCreditsModal}
          />
        ),*/
        Edit: (
          <Edit
            playerInfo={row}
            setplayerModalEdit={setplayerModalEdit}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setPlayerRating={setPlayerRating}
            setPlayerClubRating={setPlayerClubRating}
            setPlayerUSPARating={setPlayerUSPARating}
            setPlayerIsPro={setPlayerIsPro}
            setChukkerNumber={setChukkerNumber}
            setProRequest={setProRequest}
            setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
            setPlayerModal={setPlayerModal}
          />
        ),
        Archive: (
          <Archive
            playerId={row["player_id"]}
            playerArchived={row["is_archived"]}
            updateArchiveStatus={updateArchiveStatus}
            lastActive={row["last_signup"]}
          />
        ),
        Delete: (
          <Delete
            playerInfo={row}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setDeleteModal={setDeleteModal}
          />
        ),
      };
    }
    return player_mapping;
  }
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Player Name");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    const orderByMapped = columnMapper[orderBy];
    if (b[orderByMapped] < a[orderByMapped]) {
      return -1;
    }
    if (b[orderByMapped] > a[orderByMapped]) {
      return 1;
    }
    return 0;
  }

  const getComparator = useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, [])

  const visibleRows = useMemo(
    () => [...signups].sort(getComparator(order, orderBy)),
    [order, orderBy, signups, getComparator]
  );
  return (
    <Paper elevation={24}>
      <TableContainer component={Paper} data-testid={"table-signup"}>
        <Table stickyHeader arial-label="signup table">
          <TableHead>
            <TableRow style={{ position: "sticky", zIndex: 800 }}>
              {columns.map((column, index) => {
                return (
                  <TableCell
                    align="center"
                    style={{
                      left: index === 0 ? 0 : "",
                      background: teamColor,
                      color: "white",
                      zIndex: index === 0 ? 800 : "",
                      position: index === 0 ? "sticky" : "",
                    }}
                    width="200px"
                    sortDirection={orderBy === column ? order : false}
                    //rowSpan={2}
                  >
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? order : "asc"}
                      onClick={createSortHandler(column)}
                    >
                      {column}
                      {orderBy === column ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, signupIndex) => {
              const player_mapping = get_rows(page, row);
              if (
                (row["first_name"] + " " + row["last_name"])
                  .toLowerCase()
                  .match(searchField) &&
                (!doNotShowArchive || !row["is_archived"])
              ) {
                return (
                  <StyledTableRow key={signupIndex}>
                    {columns.map((column, index) => {
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{
                            left: index === 0 ? 0 : "",
                            zIndex: index === 0 ? 600 : "",
                            position: index === 0 ? "sticky" : "",
                            backgroundColor:
                              index === 0
                                ? row["is_archived"]
                                  ? "rgba(93, 138, 168, 0.10)"
                                  : teamColor
                                : "",
                            color:
                              index === 0 && !row["is_archived"] ? "white" : "",
                          }}
                        >
                          {player_mapping[column]}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default PlayerSignupTable;

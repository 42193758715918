import { IconButton, Tooltip } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";

function ChukkerbookAccount({ hasAccount, disabled }) {
  if (hasAccount) {
    return (
      <Tooltip title="Player has their own Chukkerbook account">
        <IconButton>
          <VerifiedIcon color={disabled ? "" : "secondary"} />
        </IconButton>
      </Tooltip>
    );
  } else {
    return "-";
  }
}
export default ChukkerbookAccount;

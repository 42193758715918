import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useNoSubscriptionRedirect(
  betaMode: boolean,
  clubId: number,
  managerMode: number,
  devPort: string
) {
  let navigate = useNavigate();
  useEffect(() => {
    if (!devPort) {
      navigate("/");
    }
    if (managerMode === -1 && betaMode) {
      console.log("Checking restrictions...");
      const subscription_fetch = devPort + "get_club_subscription/" + clubId;
      fetch(subscription_fetch)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data && "id" in data) {
            console.log("Subscription");
            console.log(data);
            if (
              "allowed_players" in data &&
              "max_allowed_players" in data &&
              data["allowed_players"] > data["max_allowed_players"]
            ) {
              console.log("Invalid Membership");
              navigate("/");
            } else {
              console.log("Valid Membership");
            }
          } else {
            console.log("Invalid Membership");
            navigate("/");
          }
        });
    }
  }, [devPort, clubId, managerMode, betaMode, navigate]);
}

export default useNoSubscriptionRedirect;

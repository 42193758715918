import { Card, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";

export interface chartData {
  x: number[];
  y: { [y_key: string]: number[] };
}

function ChartCard({ children, title }: { children: JSX.Element,  title: string }) {
  return (
    <Card
      variant="outlined"
      //style={{ borderColor: "#5D8AA8", borderWidth: "medium", backgroundColor: "rgba(93, 138, 168, 0.55)" }}
      style={{ backgroundColor: "rgba(93, 138, 168, 0.10)" }}
    >
      <CardContent>
        <Stack gap={2}>
          <Typography variant="h6">{title}</Typography>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ChartCard;

import { Card, CardContent, Stack, Typography } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import InfoIcon from "@mui/icons-material/Info";
import RedeemIcon from '@mui/icons-material/Redeem';

import chukkerbookTheme from "../../chukkerbbokTheme.tsx";
function BannerMessage({ message, color }) {
  return (
    <Card
      variant="outlined"
      style={{
        backgroundColor: chukkerbookTheme[color],
        color: color !== "success" ? "white" : "",
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            {color === "primary" && <ConfirmationNumberIcon />}
            {color === "secondary" && <InfoIcon />}
            {color === "success" && <RedeemIcon />}
            <Typography>{message}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
export default BannerMessage;

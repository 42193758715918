import { IconButton, Tooltip } from "@mui/material";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
function SignedWaiver({ hasSigned, disabled }) {
  console.log("PAYER");
  if (!hasSigned) {
    return (
      <Tooltip title="Waivers missing">
        <IconButton>
          <ContentPasteOffIcon color={disabled ? "" : "secondary"} />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Waivers complete">
      <IconButton>
        <AssignmentTurnedInIcon color={disabled ? "" : "success"} />
      </IconButton>
    </Tooltip>
  );
}

export default SignedWaiver;

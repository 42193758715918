import { LineChart } from "@mui/x-charts/LineChart";
import seriesData from "./seriesData.ts";
import ChartCard, { chartData } from "./ChartCard.tsx";

function LineChartTile({
  title,
  filled,
  chartData,
}: {
  title: string;
  filled: boolean;
  chartData: chartData;
}) {
  return (
    <ChartCard title={title}>
      <LineChart
        xAxis={[{ data: chartData["x"], scaleType: "point" }]}
        series={seriesData(chartData, filled)}
        height={300}
      />
    </ChartCard>
  );
}

export default LineChartTile;

import { BarChart } from "@mui/x-charts";
import seriesData from "./seriesData.ts";
import ChartCard, { chartData } from "./ChartCard.tsx";

function BarChartTile({
  title,
  filled,
  chartData,
}: {
  title: string;
  filled: boolean;
  chartData: chartData;
}) {
  return (
    <ChartCard title={title}>
      <BarChart
        xAxis={[{ data: chartData["x"], scaleType: "band" }]}
        series={seriesData(chartData, filled)}
        height={300}
      />
    </ChartCard>
  );
}

export default BarChartTile;

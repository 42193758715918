import { Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import LineChartTile from "../components/Analytics/LineChartTile.tsx";
import { Stack } from "@mui/system";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import BarChartTile from "../components/Analytics/BarChartTile.tsx";

function AnalyticsPage({
  clubId,
  devPort,
  waiverNotifications,
  //setWaiverNotifications,
  managerMode,
}: {
  clubId: number;
  devPort: string;
  waiverNotifications: number;
  //setWaiverNotifications: Dispatch<SetStateAction<number>>;
  managerMode: number;
}) {
  const [tileWidth, setTileWidth] = useState(6);
  const [weeklyGames, setWeeklyGames] = useState();
  const [weeklySignups, setWeeklySignups] = useState();

  const [weeklyLessons, setWeeklyLessons] = useState();
  const [weeklyLessonSignups, setWeeklyLessonSignups] = useState();

  function handleResize() {
    if (window.innerWidth < 700) {
      setTileWidth(700);
    } else {
      setTileWidth(6);
    }
  }
  useEffect(() => {
    handleResize();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //const chukkers = { x: [1, 2, 3, 4, 5, 6], y: { "outdoor": [10, 20, 20, 30, 10, 40], "indoor": [8, 12, 10, 6, 3, 7] } }
  //const lessons = { x: [1, 2, 3, 4, 5, 6], y: { "outdoor": [8, 12, 10, 6, 3, 7], "indoor": [10, 20, 20, 30, 10, 40] } }
  //const players = { x: [1, 2, 3, 4, 5, 6], y: { "arena": [32, 44, 48, 42, 57, 41], "grass": [10, 20, 20, 30, 10, 40] } }
  //const revenue = { x: [1, 2, 3, 4, 5, 6], y: { "US Dollars": [1000, 2000, 2500, 2500, 1500, 3000] } }

  useEffect(() => {
    const analytics_fetch = devPort + "get_club_analytics/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("ANALYTICS DATA")
        console.log(data);
        setWeeklyGames(data['weekly_games'])
        setWeeklySignups(data['weekly_signups'])
      });
  }, [devPort, clubId]);

  useEffect(() => {
    const analytics_fetch = devPort + "get_club_lesson_analytics/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("LESSON ANALYTICS DATA")
        console.log(data);
        setWeeklyLessons(data['weekly_lessons'])
        setWeeklyLessonSignups(data['weekly_signups'])
      });
  }, [devPort, clubId]);

  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications}>
      <Card variant="outlined">
        <CardContent>
          <Stack gap="md">
            <h1>Analytics Dashboard</h1>
            <Grid2 container spacing={2}>
              {weeklySignups &&
                <Grid2 xs={tileWidth}>
                  <LineChartTile
                    title="Weekly Player Signups"
                    filled={false}
                    chartData={weeklySignups}
                  />
                </Grid2>

              }
              {weeklyGames &&
                <Grid2 xs={tileWidth}>
                  <BarChartTile
                    title="Games per Week"
                    filled={false}
                    chartData={weeklyGames}
                  />
                </Grid2>
              }
              {weeklyLessonSignups &&
                <Grid2 xs={tileWidth}>
                  <LineChartTile
                    title="Weekly Lesson Signups"
                    filled={false}
                    chartData={weeklyLessonSignups}
                  />
                </Grid2>
              }
              {weeklyLessons &&

                <Grid2 xs={tileWidth}>
                  <BarChartTile
                    title="Lessons Per Week"
                    filled={false}
                    chartData={weeklyLessons}
                  />
                </Grid2>
              }
              {/*<Grid2 xs={tileWidth}>
                <LineChartTile
                  title="Number of players per month"
                  filled={false}
                  chartData={players}
                />
              </Grid2>
              <Grid2 xs={tileWidth}>
                <LineChartTile
                  title="Stripe revenue per month"
                  filled={true}
                  chartData={revenue}
                />
            </Grid2>*/}
            </Grid2>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}
export default AnalyticsPage;

//import classes from './Layout.module.css';
import GameTaskBar from "./GameTaskBar";
function Layout(props) {
  let pagesNav = {
    //'Select Club': '/',
    Games: "/game",
    Lessons: "/lesson",
    Players: "/player",
  };
  if (props.managerMode > -1) {
    pagesNav = {
      //'Select Club': '/playerclubs',
      Games: "/playergame",
      Lessons: "/lesson",
      Players: "/playerclub",
    };
  }
  if (props.managerMode === -3) {
    pagesNav = {
      //'Select Club': '/playerclubs',
      Login: "/account-login",
      Signup: "/account-signup",
    };
  }
  if (props.managerMode === -4) {
    pagesNav = {
      //'Select Club': '/playerclubs',
      Dashboard: "/club",
      //'Logout': '/account-login'
    };
  }
  if (props.managerMode === -5) {
    pagesNav = {
      //'Select Club': '/playerclubs',
      Dashboard: "/playerclubselection",
      //'Logout': '/account-login'
    };
  }
  return (
    <div>
      <GameTaskBar
        pagesNav={pagesNav}
        displayContent={props.managerMode}
        devPort={props.devPort}
        waiverNotifications={props.waiverNotifications}
        managerMode={props.managerMode}
        betaMode={props.betaMode}
      />
      <main /*className={classes.main}*/>{props.children}</main>
    </div>
  );
}

export default Layout;

import { Fab, Tooltip } from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import moment from "moment";

function DisplayArchiveIcon({ is_archived }: { is_archived: boolean }) {
  if (is_archived) {
    return <UnarchiveIcon />;
  } else {
    return <ArchiveIcon />;
  }
}
export default function Archive({
  playerId,
  playerArchived,
  updateArchiveStatus,
  lastActive,
}: {
  playerId: number;
  playerArchived: boolean;
  updateArchiveStatus: (playerId: number, playerArchived: boolean) => void;
  lastActive: moment.Moment;
}) {
  const today = moment();
  const allowedArchive = !lastActive || today.diff(lastActive, "days") > 30;
  return (
    <Tooltip title="Players who have been inactive for at least 30 days can be archived">
      <span>
        <Fab
          color={playerArchived ? "success" : "primary"}
          size="small"
          aria-label="archive"
          disabled={!playerArchived && !allowedArchive}
          onClick={() => {
            updateArchiveStatus(playerId, !playerArchived);
          }}
        >
          <DisplayArchiveIcon is_archived={playerArchived} />
        </Fab>
      </span>
    </Tooltip>
  );
}

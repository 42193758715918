import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DeletionModal from "../ui/DeletionModal";
import RegistrationModal from "./RegistrationModal";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import NotesModal from "../Signups/NotesModal";
import chukkerbookTheme from "../../chukkerbbokTheme.tsx";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import getWaiverLinks from "../Settings/getWaiverLinks";
import hasPlayerSignegWaiver from "./hasPlayerSignedWaiver";
import PlayerSignupTable from "../Signups/PlayerSignupTable";
import MyPlayerSignup from "../Signups/MyPlayerSignup";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
//import CreditModal from "../Signups/CreditModal";
import PlayerUsage from "../Signups/PlayerUsage.tsx";

function PlayerList({
  devPort,
  loadingPage,
  managerMode,
  title,
  clubId,
  gameId,
  players,
  nameEdit,
  ratingEdit,
  chukkerEdit,
  refresh,
  playerDelete,
  updateArchiveStatus,
  proColorMapping,
  gameMode,
  waiverNotifications,
  setWaiverNotifications,
  tableMode,
  proMapping,
  totalNumberLeasedChukkers,
  totalNumberLeasedChukkersAvailable,
  playersUsage,
  betaMode
}) {
  const [playerModal, setPlayerModal] = useState(false);
  //const [creditsModal, setCreditsModal] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [playerModalEdit, setplayerModalEdit] = useState(true);
  const [playerFirstName, setPlayerFirstName] = useState("");
  const [playerLastName, setPlayerLastName] = useState("");
  const signupNotes = "";
  const [playerId, setPlayerId] = useState();
  const [playerRating, setPlayerRating] = useState(-2);
  const [playerClubRating, setPlayerClubRating] = useState(-2);
  const [playerUSPARating, setPlayerUSPARating] = useState(-2);
  const [chukkerNumber, setChukkerNumber] = useState(2);
  const [playerIsPro, setPlayerIsPro] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [playerHasSignedWaiver, setPlayerHasSignedWaiver] = useState(false);
  const [doNotShowArchive, setDoNotShowArchive] = useState(false);
  let navigate = useNavigate();
  console.log(players);

  const [displayMySignupInfo, setDisplayMySignupInfo] = useState();
  useEffect(() => {
    let displayInfo = null;
    if (players) {
      players.map((player) => {
        if (player["player_id"] === managerMode) {
          displayInfo = player;
        }
        return null;
      });
    }
    setDisplayMySignupInfo(displayInfo);
  }, [players, managerMode]);

  function DisplayChukkerGeneration() {
    if (chukkerEdit && gameMode !== "lesson") {
      if (managerMode === -1) {
        return (
          <Button
            variant="contained"
            color="success"
            onClick={() => navigate("/matchup")}
          >
            {" "}
            Generate Chukkers!
          </Button>
        );
      } else {
        return (
          <Button
            variant="contained"
            color="success"
            onClick={() => navigate("/playermatchup")}
          >
            {" "}
            See Chukkers!
          </Button>
        );
      }
    }
    return;
  }
  function addPlayer() {
    if (chukkerEdit) {
      if (gameMode === "lesson") {
        navigate("/addlessonplayer");
      } else {
        if (managerMode === -1) {
          navigate("/addplayer");
        } else {
          navigate("/playerregister");
        }
      }
    } else {
      setplayerModalEdit(false);
      setPlayerModal(true);
    }
  }
  let registrationContent = "Add New Player";
  if (managerMode !== -1) {
    if (chukkerEdit) {
      if (gameMode === "lesson") {
        registrationContent = "Register for the Lesson";
      } else {
        registrationContent = "Register for the Game";
      }
    } else {
      registrationContent = "Join the club";
    }
  }
  const [searchPlayerName, setsearchPlayerName] = useState("");
  function updateSearchPlayerName(event) {
    if (!event.target.value.match(/[%<>\\$'"(){}]/)) {
      setsearchPlayerName(event.target.value.toLowerCase());
    }
  }
  const [proRequest, setProRequest] = useState();
  const getNumberMissingWaiver = useCallback(() => {
    const fetch_missing_waiver =
      devPort + "club_number_missing_waiver/" + clubId;
    console.log(fetch_missing_waiver);
    fetch(fetch_missing_waiver)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Look at this MIsdvbbf waivers");
        if (data && data[0]) {
          console.log(data[0][0]);
          setWaiverNotifications(data[0][0]);
        }
        console.log(data);
      });
  }, [devPort, clubId, setWaiverNotifications]);

  const [waiverLinks, setWaiverLinks] = useState([]);
  useEffect(() => {
    if (!chukkerEdit) {
      if (managerMode === -1) {
        getNumberMissingWaiver();
      } else {
        getWaiverLinks(devPort, clubId, setWaiverLinks);
        hasPlayerSignegWaiver(
          devPort,
          clubId,
          managerMode,
          setPlayerHasSignedWaiver
        );
      }
    }
  }, [
    chukkerEdit,
    players,
    devPort,
    clubId,
    managerMode,
    getNumberMissingWaiver,
  ]);

  return (
    <Card variant="outlined">
      <CardContent>
        <RegistrationModal
          managerMode={managerMode}
          devPort={devPort}
          editMode={playerModalEdit}
          clubId={clubId}
          gameId={chukkerEdit ? gameId : 0}
          playerId={playerId}
          playerModal={playerModal}
          setPlayerModal={setPlayerModal}
          baseEdit={!chukkerEdit}
          firstName={playerFirstName}
          setFirstName={setPlayerFirstName}
          lastName={playerLastName}
          setLastName={setPlayerLastName}
          rating={playerRating}
          setRating={setPlayerRating}
          playerClubRating={playerClubRating}
          setPlayerClubRating={setPlayerClubRating}
          playerUSPARating={playerUSPARating}
          setPlayerUSPARating={setPlayerUSPARating}
          playerHasSignedWaiver={playerHasSignedWaiver}
          setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
          chukkerNumber={chukkerNumber}
          setChukkerNumber={setChukkerNumber}
          refresh={refresh}
          proRequest={proRequest}
          isPro={playerIsPro}
          setIsPro={setPlayerIsPro}
        />
        <DeletionModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteString={playerFirstName}
          deleteId={playerLastName}
          setDeleteId={setPlayerId}
          onDelete={() => {
            playerDelete(playerId);
            setDeleteModal(false);
          }}
        />
        <NotesModal
          notesModal={notesModal}
          setNotesModal={setNotesModal}
          signupNotes={signupNotes}
        />
        {/*<CreditModal
          editCreditModal={creditsModal}
          setEditCreditModal={setCreditsModal}
          devPort={devPort}
          clubId={clubId}
          playerId={playerId}
        />*/}
        <Stack spacing={2}>
          <h1>{title}</h1>
          {!chukkerEdit &&
            ((managerMode === -1 && waiverNotifications !== 0) ||
              (managerMode !== -1 &&
                !playerHasSignedWaiver &&
                waiverLinks.length !== 0)) && (
              <Card
                variant="outlined"
                style={{
                  backgroundColor: chukkerbookTheme["secondary"],
                  color: "white",
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Stack direction="row" spacing={2}>
                      <ContentPasteOffIcon />
                      <Typography>
                        {managerMode === -1
                          ? waiverNotifications +
                            " Players have not signed the waivers!"
                          : "Our records indicate that you have not signed the waivers for that club. If you already have done it, you can ignore this message. Otherwise, please sign the following waivers:"}
                      </Typography>
                    </Stack>
                    {waiverLinks.map((link, index) => {
                      console.log("WAIVER BYTTON");
                      return (
                        <Button
                          variant="contained"
                          onClick={() =>
                            window.open(
                              link[1].match("http")
                                ? link[1]
                                : "https://" + link[1]
                            )
                          }
                        >
                          {link[0]}
                        </Button>
                      );
                    })}
                  </Stack>
                </CardContent>
              </Card>
            )}
          {tableMode === "games" && managerMode === -1 && (
            <Card
              variant="outlined"
              style={{
                backgroundColor: chukkerbookTheme["primary"],
                color: "white",
              }}
            >
              <CardContent>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <ConfirmationNumberIcon />
                    <Typography>
                      {totalNumberLeasedChukkers +
                        " horse chukkers have been leased so far out of " +
                        totalNumberLeasedChukkersAvailable}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          )}
          {playersUsage && (betaMode || managerMode === -1) && (
            <PlayerUsage members_details={playersUsage} />
          )}
          {tableMode === "members" && managerMode === -1 && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doNotShowArchive}
                    onChange={() => setDoNotShowArchive(!doNotShowArchive)}
                  />
                }
                label="Do not show archived players"
              />
            </FormGroup>
          )}
          {tableMode &&
            players &&
            managerMode !== -1 &&
            displayMySignupInfo && (
              <MyPlayerSignup
                playerInfo={displayMySignupInfo}
                proColorMapping={proColorMapping}
                setPlayerId={setPlayerId}
                setPlayerFirstName={setPlayerFirstName}
                setPlayerLastName={setPlayerLastName}
                setDeleteModal={setDeleteModal}
                setplayerModalEdit={setplayerModalEdit}
                setPlayerRating={setPlayerRating}
                setPlayerClubRating={setPlayerClubRating}
                setPlayerUSPARating={setPlayerClubRating}
                setPlayerIsPro={setPlayerIsPro}
                setChukkerNumber={setChukkerNumber}
                setProRequest={setProRequest}
                setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
                setPlayerModal={setPlayerModal}
                proMapping={proMapping}
                page={tableMode}
              />
            )}
          <TextField
            id="filter-player-name"
            label="Search for Player"
            style={{ width: "100%" }}
            variant="filled"
            color="secondary"
            onChange={updateSearchPlayerName}
          />
          {tableMode && players && players.length !== 0 && (
            <PlayerSignupTable
              signups={players}
              searchField={searchPlayerName}
              proColorMapping={proColorMapping}
              page={tableMode}
              managerMode={managerMode}
              setPlayerId={setPlayerId}
              setPlayerFirstName={setPlayerFirstName}
              setPlayerLastName={setPlayerLastName}
              setDeleteModal={setDeleteModal}
              setplayerModalEdit={setplayerModalEdit}
              setPlayerRating={setPlayerRating}
              setPlayerClubRating={setPlayerClubRating}
              setPlayerUSPARating={setPlayerUSPARating}
              setPlayerIsPro={setPlayerIsPro}
              setChukkerNumber={setChukkerNumber}
              setProRequest={setProRequest}
              setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
              setPlayerModal={setPlayerModal}
              proMapping={proMapping}
              //setCreditsModal={setCreditsModal}
              updateArchiveStatus={updateArchiveStatus}
              doNotShowArchive={doNotShowArchive}
            />
          )}
          {!loadingPage && (
            <Stack spacing={3}>
              {(managerMode === -1 || chukkerEdit) && (
                <Button variant="contained" color="success" onClick={addPlayer}>
                  {" "}
                  {registrationContent}
                </Button>
              )}
              <DisplayChukkerGeneration />
            </Stack>
          )}
        </Stack>
        {loadingPage && <LinearProgress color="secondary" />}
      </CardContent>
    </Card>
  );
}

export default PlayerList;

import { IconButton, Tooltip } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";

function ProPlayer({ isPro, disabled }) {
  if (isPro) {
    return (
      <Tooltip title="Pro Player">
        <IconButton>
          <SchoolIcon color={disabled ? "" : "secondary"} />
        </IconButton>
      </Tooltip>
    );
  } else {
    return "-";
  }
}

export default ProPlayer;
